<template>
  <div class="contenedor-nav flex">
      <h1>Sistema de Monitoreo de Igualdad</h1>
  </div>
</template>

<script>
export default {
    name:'Navmenu'
}
</script>

<style lang="scss" scoped>
    .contenedor-nav{
        background-color: #392f5c;
        height:60px;
        width:100%;
        & h1 {
            margin:0;
            color: #fff;
            font-size:16px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
</style>